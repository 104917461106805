import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, MenuController } from '@ionic/angular';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  templateUrl: './provider-layout.page.html',
  styleUrls: ['./provider-layout.page.scss']
})
export class ProviderLayoutPage implements OnInit {
  menus: any = [];
  currentUser: any;
  userMType: any;
  selectedMenu: any;
  notificationsVisible: boolean = false;
  notificationCount = 0; // Variable to hold the count
  previousNotificationCount = 0; // To track the previous count
  notifications: any[] = [];
  isSubmenuOpen = false;
  isClientMenuOpen = false;


  notificationSound: HTMLAudioElement;

  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    public menuController: MenuController,
    private menu: MenuController,
    private cdRef: ChangeDetectorRef
  ) {
    this.initializeApp();
    this.enableSound(); // Enable sound on first user interaction
  }

  ngOnInit() {
   
  }
  openProviderMenu() {
    this.menu.open('first');
  }

  toggleClientMenu() {
    this.isClientMenuOpen = !this.isClientMenuOpen; // Toggle submenu visibility
  }

  closeSubMenu() {
    this.isClientMenuOpen = false; // Close the submenu when clicking on a submenu item
  }


  toggleSubmenu() {
    this.isSubmenuOpen = !this.isSubmenuOpen;
  }

  


  private enableSound() {
    // this.notificationSound = new Audio('assets/notification.mp3');
    this.notificationSound = new Audio('assets/sounds/notification.wav'); // Load the notification sound

    this.notificationSound.load(); // Load the sound
  }

  playTestSound() {
    this.notificationSound.play().catch(error => {
        console.error("Error playing test sound:", error);
    });
}


  loadNotificationCount() {
    this.authService.getNotificationCount().subscribe(
      (response) => {
        const newCount = response.resultObj.totalNotificationsCount;
        console.log('New Notification Count:', newCount);
        console.log('Previous Notification Count:', this.previousNotificationCount);

        this.notificationCount = newCount;
        this.notifications = new Array(this.notificationCount); // Create an array of the length for the badge
        
        // Check if the count has increased
        if (this.notificationCount > this.previousNotificationCount) {
          console.log('Playing sound due to count increase');
          this.playNotificationSound(); // Play sound if new notifications exist
          this.cdRef.detectChanges();

        }
        
        // Update the previous count
        this.previousNotificationCount = this.notificationCount;
      },
      (error) => {
        console.error('Error fetching notification count:', error);
      }
    );
  }

  openNotifications() {
    this.notificationsVisible = !this.notificationsVisible; // Toggle visibility
  }

  seeMore() {
    this.router.navigate(['/provider/notification']); // Adjust the route as necessary
  }

  openMenu() {
    this.menuController.open('end');
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.setDefaultLang(event.lang);
        this.menus = event.translations.PROVIDERMENUS;
      });

      this.translate.get(['PROVIDERMENUS']).subscribe((data) => {
        this.menus = data.PROVIDERMENUS;
      });

      let findUserType = JSON.parse(localStorage.getItem('authUser'));
      if (findUserType.type == 1) {
        this.userMType = "Main";
      } else if (findUserType.type == 2) {
        this.userMType = "Mini";
      }

      this.authService.onCurrentUserChange.subscribe(user => {
        this.currentUser = user;   
      });

      if(this.currentUser.isAdminAccess==1){
        this.loadNotificationCount();

  }
  
    });
  }

  playNotificationSound() {
    this.notificationSound.play().catch(error => {
      console.error("Error playing sound:", error);
    });
  }

  gotoProfile() {
    this.router.navigate(['provider/profile']);
  }

  gotoHome() {
    this.router.navigate(['/']);
  }

  signout() {
    this.menuController.close();
    this.authService.logout();
  }

  notification() {
    this.router.navigate(['provider/notification-list']);
  }
}
