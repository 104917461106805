import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
// import { map } from 'rxjs/internal/operators/map';;
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { HELPERS } from '../_helpers/helpers';

@Injectable({
  providedIn: 'root'
})
export class RewardCriteriaService {
  private _baseUrl = environment.API_URL;
  private _headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private totalSubject = new Subject<number>();

  constructor(private http: HttpClient) { }

  onGetTotal(): Observable<any> {
    return this.totalSubject.asObservable();
  }

  //  delete action logs with specific table data by event_id
  public deleteActionLogs(id) {
    let apiEndPoint = `/notifications/pdelete?id=${id}`;
    return this.http.get<any>(this._baseUrl + apiEndPoint,  this._headers);
  }

  public getProviderCriteria(id) {
    let apiEndPoint = '/rewardcriteria/getall';
    return this.http.get<any>(this._baseUrl + apiEndPoint + id, this._headers);
  }

  //getAllProviderCriteria
  public getAllProviderCriteria(id) {
    let apiEndPoint = '/rewardcriteria/getall?status=1&approvalStatus=approved';
    return this.http.get<any>(this._baseUrl + apiEndPoint + id, this._headers);
  }

  public getAllProviderCriteriaByDate = (id) => {
    let apiEndPoint = '/rewardcriteria/getall';
    return this.http.get<any>(this._baseUrl + apiEndPoint + id, this._headers);
  }

  public getRewardCriteriaById = criteriaId => {
    let apiEndPoint = '/rewardcriteria/get/'
    return this.http.get<any>(this._baseUrl + apiEndPoint + criteriaId, this._headers);
  }

  public getReminderById(id) {
    let apiUrl = `${environment.API_URL}/userevent/getById/?id=${id}`;
    let apiEndPoint = `/userevent/getById/?id=${id}`;
    // let apiEndPoint = apiUrl;
    return this.http.get<any>(this._baseUrl + apiEndPoint , this._headers);
  }

  public getBankById(id) {
    let apiUrl = `${environment.API_URL}/userevent/getById/?id=${id}`;
    let apiEndPoint = `/userevent/getById/?id=${id}`;
    // let apiEndPoint = apiUrl;
    return this.http.get<any>(this._baseUrl + apiEndPoint , this._headers);
  }

  public updateStatus = payload => {
    let apiEndPoint = '/rewardcriteria/updatestatus';
    return this.http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }


  public addRewardCriteria(payload): Observable<any> {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let webAppPath = '/rewardcriteria/create';
    return this.http.post(this._baseUrl + webAppPath, payload, httpOption);
  }

  public addReminder(payload): Observable<any> {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let webAppPath = '/userevent/create';
    return this.http.post(this._baseUrl + webAppPath, payload, httpOption);
  }

  public editReminder(payload): Observable<any> {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let webAppPath = '/userevent/update';
    return this.http.post(this._baseUrl + webAppPath, payload, httpOption);
  }

  public editBank(payload): Observable<any> {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let webAppPath = '/userevent/update';
    return this.http.post(this._baseUrl + webAppPath, payload, httpOption);
  }

  // products
  public addProduct(payload): Observable<any> {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let webAppPath = '/userevent/create';
    return this.http.post(this._baseUrl + webAppPath, payload, httpOption);
  }

  public editProduct(payload): Observable<any> {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let webAppPath = '/userevent/update';
    return this.http.post(this._baseUrl + webAppPath, payload, httpOption);
  }

  
  public updateRewardCriteria = payload => {
    let apiEndPoint = '/rewardcriteria/update';
    return this.http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }
  public getProviderRewardHistory(providerId) {
    let apiEndPoint = `/rewards/getproviderrewards?providerId=${providerId}`;
    return this.http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }

  public getRewardsByDate = (providerId) => {
    let apiEndPoint = '/rewards/getproviderrewards';
    return this.http.get<any>(this._baseUrl + apiEndPoint + providerId, this._headers);
  }
  public getReminderListforHome() {
    let apiEndPoint = `/userevent/get`;
    return this.http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }
  public getReminderList(params) {
    let apiEndPoint = `/userevent/get${params}`;
    return this.http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }




  public getClientListforHome() {
    let apiEndPoint = `/userevent/get`;
    return this.http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }
  




  public getClientById(id) {
    let apiUrl = `${environment.API_URL}/users/getById/${id}`;
    let apiEndPoint = `/users/${id}`;
    // let apiEndPoint = apiUrl;
    return this.http.get<any>(this._baseUrl + apiEndPoint , this._headers);
  }



  public addClient(payload): Observable<any> {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let webAppPath = '/users/create';
    return this.http.post(this._baseUrl + webAppPath, payload, httpOption);
  }

  // validateCustomerMobile(primaryPhone: string) {
  //   return this.http.get(`${environment.API_URL}/users/validateclient`, { params: { primaryPhone } });
  // }

  // validateCustomerUsername(username: string) {
  //   return this.http.get(`${environment.API_URL}/client/validateclient`, { params: { username } });
  // }


  validateCustomerMobile(primaryPhone: string): Observable<boolean> {
    return this.http.get<any>(`${environment.API_URL}/client/validateclient`, { params: { primaryPhone } }).pipe(
      map(response => !!response.resultObj) // Convert resultObj to boolean
    );
  }

  validateCustomerUsername(username: string): Observable<boolean> {
    return this.http.get<any>(`${environment.API_URL}/client/validateclient`, { params: { username } }).pipe(
      map(response => !!response.resultObj) // Convert resultObj to boolean
    );
  }
  

  public getClientList(params) {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let apiEndPoint = `/users/${params}`;
    return this.http.get<any>(this._baseUrl + apiEndPoint, httpOption);
  }

  public getClientAllBanks(params) {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let apiEndPoint = `/client/get_all_client_banks/${params}`;
    return this.http.get<any>(this._baseUrl + apiEndPoint, httpOption);
  }

  public assignBankToCustomer(payload): Observable<any> {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let webAppPath = '/users/assign_bank';
    return this.http.post(this._baseUrl + webAppPath, payload, httpOption);
  }

  public editClient(payload): Observable<any> {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let webAppPath = '/users/update';
    return this.http.post(this._baseUrl + webAppPath, payload, httpOption);
  }

  public updatePassword(payload): Observable<any> {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let webAppPath = '/users/update_password';
    return this.http.post(this._baseUrl + webAppPath, payload, httpOption);
  }

  public addBank(payload): Observable<any> {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let webAppPath = '/client/add_bank';
    return this.http.post(this._baseUrl + webAppPath, payload, httpOption);
  }

  public getNotification(params){
    let apiEndPoint = `/notification/getnotification${params}`;
    return this.http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }

  public getPatientRewards(params) {
    let apiEndPoint = `/rewards/getpatientrewards${params}`;
    return this.http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }

  public readNotification = payload => {
    let apiEndPoint = '/notification/readnotification';
    return this.http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }
  public deleteNotification(id) {
    let apiEndPoint = `/userevent/delete?id=${id}`;
    return this.http.get<any>(this._baseUrl + apiEndPoint,  this._headers);
  }

  public getAppointmentRewardHistory(providerId) {
    let apiEndPoint = `/rewards/getproviderrewards?providerId=${providerId}`;
    return this.http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }
  public deleteRewardCriteria = payload => {
    let apiEndPoint = '/rewardcriteria/delete';
    return this.http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }

  public updateApprovalStatus = payload => {
    let apiEndPoint = '/rewardcriteria/updateapproval';
    return this.http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }

  public updateProviderCriteriaStatus = payload => {
    let apiEndPoint = '/rewardcriteria/updateprovidercriteriastatus';
    return this.http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }


  //  chat api 
  public getListOfAllUsers(params) {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let apiEndPoint = `/client/getChatUsers/${params}`;
    return this.http.get<any>(this._baseUrl + apiEndPoint, httpOption);
  }

  public getListOfAllAdmins(params) {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let apiEndPoint = `/client/getChatUsers/${params}`;
    return this.http.get<any>(this._baseUrl + apiEndPoint, httpOption);
  }

  public sendMessage = payload => {
    let apiEndPoint = '/chat/send';
    return this.http.post<any>(`${environment.API_URL}/chat/send`, payload);
  }

  public getMessages(senderId: any, recipientId: any) {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const params = `?senderId=${senderId}&recipientId=${recipientId}`;


    let apiEndPoint = `/chat/getMessages/${params}`;
    return this.http.get<any>(this._baseUrl + apiEndPoint, httpOption);
  }

  public getIdToIdTransferRequest(params) {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let apiEndPoint = `/customer/get_requested_ids_tranfer/${params}`;

    return this.http.get<any>(this._baseUrl + apiEndPoint, httpOption);
  }

  public actionForIdToIdTransfer(payload): Observable<any> {
    let httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let webAppPath = '/customer/update_transfer_request_status';
    return this.http.post(this._baseUrl + webAppPath, payload, httpOption);
  }
}
