import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AgmCoreModule } from '@agm/core';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'ion2-calendar';
import { CalendarCommonModule as NgCalendarModule, DateAdapter } from 'angular-calendar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SimpleMaskModule } from 'ngx-ion-simple-mask';

import { SharedModule } from './pages/shared/components/shared.module';
import { FAQPage } from './pages/shared/faq/faq.page';
import { PrivacyPolicyPage } from './pages/shared/privacy-policy/privacy-policy.page';
import { ProviderAvailabilityPage } from './pages/shared/provider-availability/provider-availability.page';
import { ProviderLayoutPage } from './pages/shared/provider-layout/provider-layout.page';
import { CustomerLayoutPage } from './pages/shared/customer-layout/customer-layout.page';

import { SelectLocationPage } from './pages/shared/select-location/select-location.page';
import { SelectRewardPage } from './pages/shared/select-reward/select-reward.page';
import { SubscriptionPopupPage } from './pages/shared/subscription-popup/subscription-popup.page';
import { TermsConditionsPage } from './pages/shared/terms-conditions/terms-conditions.page';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BarRatingModule } from "ngx-bar-rating";
import { IonicRatingModule } from 'ionic-rating';
import { FaqPage } from './pages/shared/components/faq-page/faq-page.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { SupportPage } from './pages/shared/components/support/support.component';
import { OpenDescriptionPage } from './pages/provider/documents/open-description/open-description.page';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AdminListComponent } from './pages/shared/components/admin-list/admin-list.component';
import { MessageFormComponent } from './pages/shared/components/message-form/message-form.component';
import { SidebarComponent } from './pages/shared/components/sidebar/sidebar.component';
import { MessageModalComponent } from './pages/shared/components/message-modal/message-modal.component';
import { CeilPipe } from './ceil.pipe'; // Import the new component






export const config = {
  apiKey: "AIzaSyPKC2GdCpGS3iPw013CaKrly0HQW88UfZWoQPK",
  authDomain: "rdonline.firebaseapp.com",
  databaseURL: "https://rdonline.firebaseio.com",
  projectId: "rdonline",
  storageBucket: "rdonline.appspot.com",
  messagingSenderId: "243181114064388111",
  appId: "1:24318487778064388:web:1f9b988672558878e433"
};
@NgModule({
  declarations: [
    AppComponent,
    ProviderLayoutPage,
    CustomerLayoutPage,
    SelectLocationPage,
    OpenDescriptionPage,
    FaqPage,
    SupportPage,
    ProviderAvailabilityPage,
    PrivacyPolicyPage,
    TermsConditionsPage,
    SelectRewardPage,
    // MySubscriptionPage,
    // SubscriptionHistoryPage,
    // PaySubscriptionPage,
    // SubscriptionInfoPage,
    SubscriptionPopupPage,
    SidebarComponent,
    // AdminListComponent,
    // MessageFormComponent,
    // MessageModalComponent, // Declare the new component
    FAQPage,
    CeilPipe
  ],
  imports: [
    FormsModule,
    NgxDatatableModule,
    BrowserModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SimpleMaskModule,
    IonicRatingModule,
    //TooltipsModule,
    BarRatingModule,
    HttpClientModule,
    CalendarModule,
    SharedModule,
    AngularFireModule.initializeApp(config),
    AngularFireMessagingModule,
    NgCalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyPKDHcmiduScZm3pGCDhesssssPKCN9t61qZwZzGLH8aaaaa',//'YOUR-API-KEY-HERE',
      libraries: ['places']
    }),
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
