import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [ TermsAndConditionsComponent, PrivacyPolicyComponent],
  exports:[ TermsAndConditionsComponent, PrivacyPolicyComponent],
  imports: [
    IonicModule,
    CommonModule,
    //IonicModule.forRoot()
  ]  
})
export class SharedModule { }
